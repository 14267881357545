import Ag, {getBold} from '@brightlive/shared/styles/typography-v3';
import styled from 'styled-components';

const S = {
  CardContent: styled.div`
    padding: ${props => props.theme.spacing.LG}
      ${props => props.theme.spacing.MD};
    background-color: ${props => props.theme.actionColor.brand};
    border-radius: ${props => props.theme.borderRadius.LG};
  `,

  CardTitle: styled(Ag.ParagraphLG)`
    ${getBold()};
    color: ${props => props.theme.contentColor.inverse};
    margin-bottom: ${props => props.theme.spacing.XS};
  `,

  CardBody: styled(Ag.ParagraphMD)`
    margin-bottom: ${props => props.theme.spacing.LG};
    color: ${props => props.theme.contentColor.inverse};
  `,

  Button: styled(Ag.Link)``,
};
export default S;
