import React from 'react';
import types from './types';
import {AuthPages} from '@brightlive/shared/helpers/interfaces';
import Api from 'library/api';
/**
 * Toggle hamburger menu
 *
 * @return {void}
 */
export const toggleNav = toggled => ({
  type: types.TOGGLE_NAV,
  toggled,
});

export const setLoginLoading = toggled => ({
  type: types.SET_LOGIN_LOADING,
  toggled,
});

export const setLoginClosable = toggled => ({
  type: types.SET_LOGIN_CLOSABLE,
  toggled,
});
/**
 * Toggle settings modal
 *
 * @return {void}
 */
export const toggleSettingsModal = toggled => ({
  type: types.TOGGLE_SETTINGS_MODAL,
  toggled,
});
/**
 * Toggle feature lock modal
 *
 * @return {void}
 */
export const toggleFeatureLockModal = toggled => ({
  type: types.TOGGLE_FEATURES_LOCK_MODAL,
  toggled,
});
/**
 * Toggle session theme
 *
 * @return {void}
 */
export const toggleSessionTheme = theme => ({
  type: types.TOGGLE_SESSION_THEME,
  theme,
});
/**
 * Toggle login modal
 *
 * @return {void}
 */
export const toggleLoginModal = toggled => ({
  type: types.TOGGLE_LOGIN_MODAL,
  toggled,
});

/**
 * Toggle login modal
 *
 * @return {void}
 */
export const setLoginModalPage = (page: AuthPages) => ({
  type: types.SET_LOGIN_MODAL_PAGE,
  page,
});

export const setLoginModalMessage = page => ({
  type: types.SET_LOGIN_MODAL_MESSAGE,
  page,
});

export const setLoginModalEmail = email => ({
  type: types.SET_LOGIN_MODAL_EMAIL,
  email,
});

export const getSignupContent = type => ({
  type: types.GET_SIGNUP_CONTENT,
  promise: Api.get(`/signup/${type}`),
});

/**
 * Toggle private session modal
 *
 * @return {void}
 */
export const togglePrivateSessionModal = (toggled, creatorDisplayName) => ({
  type: types.TOGGLE_PRIVATE_SESSION_MODAL,
  toggled,
  creatorDisplayName,
});

/**
 * Toggle toast
 *
 * @return {void}
 */
export const toggleToast = (
  toggled,
  text: string | React.ReactNode = '',
  toastType = 'default',
  icon = '',
  placement = 'bottom'
) => ({
  type: types.TOGGLE_TOAST,
  toggled,
  text,
  toastType,
  icon,
  placement,
});
/**
 * Toggle Message Callout
 *
 * @return {void}
 */
export const toggleMessageCallout = (
  toggled: boolean,
  text = '',
  name = '',
  messageType: 'chat' | 'question' | '' = ''
) => ({
  type: types.TOGGLE_MESSAGE_CALLOUT,
  toggled,
  text,
  name,
  messageType,
});
/**
 * Toggle alert banner
 *
 * @return {void}
 */
export const toggleAlertBanner = (
  text: string,
  persist: boolean,
  alertType: 'alert' | 'warning'
) => ({
  type: types.TOGGLE_ALERT_BANNER,
  text,
  persist,
  alertType,
});
