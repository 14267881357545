import React, {useState, useRef} from 'react';
import Imgix from 'react-imgix';
// Components
import LayoutItem from '../LayoutItem';
import IconButton from '@brightlive/shared/components/IconButton';
import Accordion from '@brightlive/shared/components/Accordion';
import FileUploader from '@brightlive/shared/components/FileUploader';
import ImageUploader from 'components/shared/ImageUploader';
import Switch from '@brightlive/shared/components/Switch';
import ColorPicker from '@brightlive/shared/components/ColorPicker';
import Divider from '@brightlive/shared/components/Divider';
import {UpgradeCard} from 'bright-livekit/components/UpgradeCard';
// Images
import BannerBottomPreview from 'public/images/banner-bottom-preview.svg';
import BannerTopPreview from 'public/images/banner-top-preview.svg';
// Types
import {NameDisplayTypes, BannerLayoutTypes} from 'bright-livekit';
import {LayoutItemBase, SetLayoutFunction} from '../index';
import {Hex} from '@brightlive/shared/helpers/interfaces';
// Styles
import S from '../style';

interface BrandingSectionProps {
  sessionID: string;
  title: string;
  nameItems: LayoutItemBase[];
  bannerItems: LayoutItemBase[];
  currentBrandColor: Hex;
  currentNameDisplay: NameDisplayTypes;
  setLayout: SetLayoutFunction;
  nameDisplayVisible: boolean;
  bannerVisible: boolean;
  currentBannerImage: string;
  currentBannerLayout: BannerLayoutTypes;
  brandingEnabled: boolean;
}

const BrandingSection = ({
  sessionID,
  title,
  nameItems,
  bannerItems,
  currentBrandColor,
  setLayout,
  currentNameDisplay,
  nameDisplayVisible,
  bannerVisible,
  currentBannerImage,
  currentBannerLayout,
  brandingEnabled,
}: BrandingSectionProps) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState(false);
  const [imageCropperOpen, setImageCropperOpen] = useState(false);
  const [baseImage, setBaseImage] = useState(null);
  const [bannerImageError, setBannerImageError] = useState('');
  const [serverError, setServerError] = useState(false);
  const [bannerImg, setBannerImg] = useState(currentBannerImage);

  const handleFileChange = file => {
    if (!file) return;

    const fileWithPreview = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    setBaseImage(fileWithPreview);
    setImageCropperOpen(true);
  };

  const handleCropSuccess = async (path: string) => {
    try {
      setBannerImg(path); // set state to avoid rendering error
      setLayout({key: 'bannerImage', value: path});
      // Close Modal if successful
      setImageCropperOpen(false);
    } catch (err) {
      setImageCropperOpen(true);
      setServerError(true);
    }
  };

  const editPhoto = () => {
    setBaseImage(null);
    setBannerImg('');
    setImageCropperOpen(true);
  };

  const deletePhoto = () => {
    setBannerImg('');
    setLayout({key: 'bannerImage', value: ''});
  };

  const handleUploadClick = () => {
    if (fileInput && fileInput.current) {
      fileInput.current.click();
    }
  };

  const changePhoto = e => {
    const file = e.target.files[0];
    if (!file) return;

    const fileWithPreview = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    setBaseImage(fileWithPreview);
    setImageCropperOpen(true);
  };

  return (
    <S.Section>
      <Accordion
        size="small"
        text={title}
        open={open}
        onClick={() => setOpen(!open)}
      />
      {open && (
        <>
          {!brandingEnabled ? (
            <S.Upgrade>
              <UpgradeCard />
            </S.Upgrade>
          ) : (
            <S.BrandingContent>
              <S.Subtitle>
                We recommend making your customizations before recording.
              </S.Subtitle>
              <S.BrandingSection>
                <S.BrandingHeader>
                  <S.BrandingHeaderText>
                    <S.BrandingTitle>Brand color</S.BrandingTitle>
                    <S.BrandingSubtitle>
                      This color is used for your video background, display
                      names, and banner.
                    </S.BrandingSubtitle>
                  </S.BrandingHeaderText>
                </S.BrandingHeader>
                <S.BrandingInnerContent>
                  <ColorPicker
                    value={currentBrandColor}
                    setColor={color => {
                      setLayout({key: 'brandColor', value: color});
                    }}
                  />
                </S.BrandingInnerContent>
              </S.BrandingSection>

              <S.BrandingSection>
                <S.BrandingHeader>
                  <S.BrandingHeaderText>
                    <S.BrandingTitle>Display name</S.BrandingTitle>
                    <S.BrandingSubtitle>
                      Choose a style that fits your brand.
                    </S.BrandingSubtitle>
                  </S.BrandingHeaderText>
                  <S.Switch>
                    <Switch
                      toggled={nameDisplayVisible}
                      onToggle={() => {
                        setLayout({
                          key: 'nameDisplayVisible',
                          value: !nameDisplayVisible,
                        });
                      }}
                    />
                  </S.Switch>
                </S.BrandingHeader>
                {nameDisplayVisible && (
                  <S.BrandingInnerContent>
                    <S.NameSection>
                      {nameItems.map((item, i) => {
                        return (
                          <LayoutItem
                            key={`name-item-${i}`}
                            label={item.label}
                            image={item.image}
                            value={item.value}
                            setLayout={setLayout}
                            updateKey="nameDisplay"
                            currentValue={currentNameDisplay}
                          />
                        );
                      })}
                    </S.NameSection>
                  </S.BrandingInnerContent>
                )}
              </S.BrandingSection>
              <Divider />
              <S.BrandingSection>
                <S.BrandingHeader>
                  <S.BrandingHeaderText>
                    <S.BrandingTitle>Banner</S.BrandingTitle>
                    <S.BrandingSubtitle>
                      Add a banner that showcases your personality or describes
                      your podcast is about.
                    </S.BrandingSubtitle>
                  </S.BrandingHeaderText>
                  <S.Switch>
                    <Switch
                      toggled={bannerVisible}
                      onToggle={() => {
                        setLayout({
                          key: 'bannerVisible',
                          value: !bannerVisible,
                        });
                      }}
                    />
                  </S.Switch>
                </S.BrandingHeader>
                {bannerVisible && (
                  <S.BrandingInnerContent>
                    {!bannerImg ? (
                      <FileUploader
                        onFileChange={file => {
                          handleFileChange(file);
                        }}
                        error={bannerImageError}
                        onError={setBannerImageError}
                        instructions={
                          <>
                            Recommended dimension: 1920x160 px (12:1)
                            <br />
                            Accepted formats: JPG, PNG. Max size: 10MB.
                          </>
                        }
                      />
                    ) : (
                      <S.BannerPreview $brandColor={currentBrandColor}>
                        <Imgix
                          src={`${process.env.NEXT_PUBLIC_IMAGE_PREFIX}${bannerImg}`}
                          sizes="100%"
                          imgixParams={{ar: '12:1', fit: 'crop'}}
                        />
                        <S.BannerPreviewIcons>
                          <IconButton
                            size="small"
                            type="tertiary"
                            icon="Edit"
                            onClick={editPhoto}
                          />
                          <IconButton
                            size="small"
                            type="tertiary"
                            icon="Upload"
                            onClick={handleUploadClick}
                          />
                          <IconButton
                            size="small"
                            type="tertiary"
                            icon="Delete"
                            onClick={deletePhoto}
                          />
                        </S.BannerPreviewIcons>
                      </S.BannerPreview>
                    )}
                    {imageCropperOpen && (
                      <ImageUploader
                        closeModal={() => {
                          setBannerImg(currentBannerImage);
                          setImageCropperOpen(false);
                        }}
                        baseImage={baseImage}
                        setBaseImage={setBaseImage}
                        url={currentBannerImage}
                        imageUploadPath={`images/${sessionID}` ?? 'anon'}
                        handleCropSuccess={handleCropSuccess}
                        serverError={serverError}
                        setServerError={setServerError}
                        cropShape="rect"
                        previewImage={
                          currentBannerLayout === BannerLayoutTypes.Bottom
                            ? BannerBottomPreview
                            : BannerTopPreview
                        }
                        aspect={12 / 1}
                        cropArea={
                          currentBannerLayout === BannerLayoutTypes.Bottom
                            ? 'bottom'
                            : 'top'
                        }
                        title="Reposition banner"
                        desktopWidth="1000px"
                        imageBackgroundColor={currentBrandColor}
                      />
                    )}

                    <S.HiddenFileInput
                      type="file"
                      ref={fileInput}
                      onChange={changePhoto}
                      accept="image/*"
                    />

                    <S.BannerLayout>
                      <S.BannerLayoutTitle>Layout</S.BannerLayoutTitle>
                      <S.BannerLayoutSubtitle>
                        Choose a banner layout that best fit your brand.
                      </S.BannerLayoutSubtitle>
                      <S.BannerItems>
                        {bannerItems.map((item, i) => {
                          return (
                            <LayoutItem
                              key={`name-item-${i}`}
                              label={item.label}
                              image={item.image}
                              value={item.value}
                              setLayout={setLayout}
                              currentValue={currentBannerLayout}
                              updateKey="bannerLayout"
                            />
                          );
                        })}
                      </S.BannerItems>
                    </S.BannerLayout>
                  </S.BrandingInnerContent>
                )}
              </S.BrandingSection>
            </S.BrandingContent>
          )}
        </>
      )}
    </S.Section>
  );
};

export default BrandingSection;
