import React from 'react';
// Components
import Button from '@brightlive/shared/components/Button';
// Style
import S from './style';

export const UpgradeBanner = ({
  navExpanded = false,
  canUpgrade,
}: {
  navExpanded?: boolean;
  canUpgrade: boolean;
}) => {
  return (
    <S.UpgradeBanner $navExpanded={navExpanded}>
      <S.UpgradeBannerText>
        Recording limit reached. {canUpgrade ? 'Upgrade plan' : ''}
      </S.UpgradeBannerText>
      {canUpgrade && (
        <S.Button>
          <Button
            size="small"
            type="primary inverse"
            text="Upgrade"
            onClick={() =>
              window.open(
                `${process.env.NEXT_PUBLIC_BOOKING_URL}/pricing`,
                '_blank'
              )
            }
          />
        </S.Button>
      )}
    </S.UpgradeBanner>
  );
};
