import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAnimation} from 'framer-motion';
import {withTheme, DefaultTheme} from 'styled-components';
// Components
import Button from '@brightlive/shared/components/Button';
import TooltipWrapper from '@brightlive/shared/components/TooltipWrapper';
import {
  Role,
  QuestionInput,
  QuestionsView,
  AttendeesView,
  LayoutView,
  SessionInfoView,
  RoomState,
  ChatView,
  ChatInput,
  RecordingsView,
  LayoutUpdateProps,
  OnboardingSteps,
} from 'bright-livekit';
import IconButton from '@brightlive/shared/components/IconButton';
import Divider from '@brightlive/shared/components/Divider';
import UnreadCallout from 'components/shared/UnreadCallout';
// Redux
import {toggleSettingsModal, toggleMessageCallout} from 'redux/ui/actions';
import {UIReducer} from 'redux/ui/reducer';
// Icons
import Info from '@brightlive/shared/icons/Info';
import InfoFilled from '@brightlive/shared/icons/InfoFilled';
import Participants from '@brightlive/shared/icons/Participants';
import ParticipantsFilled from '@brightlive/shared/icons/ParticipantsFilled';
import Questions from '@brightlive/shared/icons/Questions';
import QuestionsFilled from '@brightlive/shared/icons/QuestionsFilled';
import Chat from '@brightlive/shared/icons/Chat';
import ChatFilled from '@brightlive/shared/icons/ChatFilled';
import DesignServices from '@brightlive/shared/icons/DesignServices';
import DesignServicesFilled from '@brightlive/shared/icons/DesignServicesFilled';
import Folder from '@brightlive/shared/icons/Folder';
import FolderFilled from '@brightlive/shared/icons/FolderFilled';
// Styles
import S from './styles';

interface DesktopSidebarProps {
  roomState: RoomState;
  sessionID: string;
  setToggleInviteModal: Function;
  currentTab: string | null;
  setCurrentTab: Function;
  sendQuestion: Function;
  questionText: string;
  setQuestionText: Function;
  replaceQuestion: boolean;
  setReplaceQuestion: Function;
  setLayout: <K extends keyof LayoutUpdateProps>({
    key,
    value,
  }: {
    key: K;
    value: LayoutUpdateProps[K];
  }) => void;
  onboardingStep: OnboardingSteps;
  continueOnboarding: (onboardingStep: OnboardingSteps) => void;
  needsSessionTour?: boolean;
  showQuestionPreview: boolean;
  theme: DefaultTheme;
}

const DesktopSidebarComponent = ({
  roomState,
  sessionID,
  setToggleInviteModal,
  currentTab,
  setCurrentTab,
  sendQuestion,
  questionText,
  setQuestionText,
  replaceQuestion,
  setReplaceQuestion,
  setLayout,
  onboardingStep,
  continueOnboarding,
  needsSessionTour,
  showQuestionPreview,
}: DesktopSidebarProps) => {
  const dispatch = useDispatch();
  const sidebarNavControl = useAnimation();

  const callout = useSelector((state: UIReducer) => state.ui.messageCallout);

  const chatTextareaRef = useRef<HTMLTextAreaElement>(null);
  const sidebarInnerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [chatText, setChatText] = useState('');
  const [initialTabOpen, setInitialTabOpen] = useState(false);

  const showOnboardingLayoutTooltip =
    roomState.participantsOnStage.length > 1 && onboardingStep === 'layout';

  useEffect(() => {
    if (roomState.sessionState === 'in-session' && !initialTabOpen) {
      const initialTab = needsSessionTour ? 'Session info' : 'Attendees';
      setTimeout(() => {
        setCurrentTab(initialTab);
      }, 300);
      setInitialTabOpen(true);
    }
  }, [roomState]);

  useEffect(() => {
    if (!sidebarInnerRef.current?.clientWidth) return;
    sidebarNavControl.set({opacity: 1});
    sidebarNavControl.start({
      right: currentTab ? 56 : -sidebarInnerRef.current.clientWidth,
      transition: {duration: 0.3, ease: 'easeOut'},
    });
  }, [currentTab]);
  // Disable callout if currentTab is defined or if showQuestionPreview is false
  useEffect(() => {
    if (currentTab && callout.toggled) {
      dispatch(toggleMessageCallout(false, '', '', ''));
    } else if (!showQuestionPreview && callout.toggled) {
      dispatch(toggleMessageCallout(false, '', '', ''));
    }
  }, [callout]);

  useEffect(() => {
    if (replaceQuestion) {
      textareaRef.current?.focus();
      setQuestionText('');
    }
  }, [replaceQuestion]);

  const sendChat = () => {
    if (chatText) {
      roomState.videoUtils?.submitChat(
        sessionID,
        roomState.currentUser.id,
        roomState.selfParticipant?.displayName || '',
        chatText
      );
      setChatText('');
    }
  };

  const handleRecordingsClick = () => {
    setCurrentTab(currentTab === 'Recordings' ? '' : 'Recordings');
    continueOnboarding(null);
  };
  const UnreadChatCallOut = useMemo(() => {
    if (
      !currentTab &&
      showQuestionPreview &&
      callout.toggled &&
      callout.messageType === 'chat'
    ) {
      return (
        <UnreadCallout
          text={callout.text}
          name={callout.name}
          hideCallout={() => {
            dispatch(toggleMessageCallout(false, '', '', ''));
          }}
        />
      );
    }
    return null;
  }, [callout]);

  const UnreadMessageCallOut = useMemo(() => {
    if (
      !currentTab &&
      showQuestionPreview &&
      callout.toggled &&
      callout.messageType === 'question'
    ) {
      return (
        <UnreadCallout
          text={callout.text}
          name={callout.name}
          hideCallout={() => {
            dispatch(toggleMessageCallout(false, '', '', ''));
          }}
        />
      );
    }
    return null;
  }, [callout]);

  const handleLayoutClick = () => {
    setCurrentTab(currentTab === 'Layout' ? '' : 'Layout');
    if (showOnboardingLayoutTooltip) {
      continueOnboarding('record-end');
    }
  };

  const currentUserHasQuestion =
    roomState.selfParticipant?.question &&
    !roomState.selfParticipant.questionAnswered;

  return (
    <S.LivekitSidebar>
      <S.LivekitSidebarInner
        animate={sidebarNavControl}
        initial={{right: '-33vw'}}
        ref={sidebarInnerRef}
      >
        <S.LivekitSidebarHeader>
          <IconButton
            icon="MenuOpen"
            size="medium"
            type="tertiary"
            onClick={() => setCurrentTab(null)}
          />
          <S.LivekitSidebarHeaderText>{currentTab}</S.LivekitSidebarHeaderText>
        </S.LivekitSidebarHeader>
        {currentTab === 'Session info' ? (
          <SessionInfoView
            roomState={roomState}
            onboardingStep={onboardingStep}
            continueOnboarding={continueOnboarding}
          />
        ) : null}
        {currentTab === 'Recordings' ? (
          <RecordingsView roomState={roomState} />
        ) : null}
        {currentTab === 'Attendees' ? (
          <>
            <AttendeesView
              roomState={roomState}
              replaceQuestion={() => setReplaceQuestion(true)}
            />
            <Divider />
            <S.ReplaceButton>
              <Button
                width="100%"
                type="secondary"
                size="medium"
                icon="GroupAdd"
                text="Invite"
                onClick={() => {
                  setToggleInviteModal(true);
                }}
              />
            </S.ReplaceButton>
          </>
        ) : null}
        {currentTab === 'Layout' ? (
          <LayoutView
            sessionID={sessionID}
            setLayout={setLayout}
            currentLayout={roomState.layout}
            currentScreenShareLayout={roomState.screenShareLayout}
            currentBrandColor={roomState.brandColor}
            nameDisplayVisible={roomState.nameDisplayVisible}
            currentNameDisplay={roomState.nameDisplay}
            bannerVisible={roomState.bannerVisible}
            currentBannerImage={roomState.bannerImage}
            currentBannerLayout={roomState.bannerLayout}
            roomState={roomState}
          />
        ) : null}
        {currentTab === 'Chat' ? (
          <S.ChatContainer>
            <ChatView roomState={roomState} />
            <ChatInput
              messageText={chatText}
              setMessageText={setChatText}
              sendMessage={sendChat}
              textareaRef={chatTextareaRef}
            />
          </S.ChatContainer>
        ) : null}
        {currentTab === 'Questions' && sessionID ? (
          <S.ChatContainer>
            <QuestionsView
              roomState={roomState}
              sessionID={sessionID}
              replaceQuestion={() => setReplaceQuestion(true)}
            />
            {replaceQuestion ? (
              <S.BoxShadow>
                <QuestionInput
                  questionText={questionText}
                  setQuestionText={setQuestionText}
                  sendQuestion={sendQuestion}
                  roomState={roomState}
                  textareaRef={textareaRef}
                />
                <Divider />
                <S.SubmitQuestionButtonWrapper>
                  <S.CancelButton>
                    <Button
                      type="tertiary"
                      size="small"
                      text="Cancel"
                      onClick={() => {
                        setReplaceQuestion(false);
                      }}
                    />
                  </S.CancelButton>
                  <Button
                    type="primary"
                    size="medium"
                    text="Submit"
                    disabled={!questionText.trim() || questionText.length > 140}
                    onClick={() => {
                      sendQuestion();
                      setReplaceQuestion(false);
                    }}
                  />
                </S.SubmitQuestionButtonWrapper>
              </S.BoxShadow>
            ) : (
              <>
                <Divider />
                <S.ReplaceButton>
                  <Button
                    width="100%"
                    type={currentUserHasQuestion ? 'secondary' : 'primary'}
                    size="medium"
                    disabled={roomState.selfParticipant?.role === Role.Banned}
                    text={
                      currentUserHasQuestion
                        ? 'Replace comment'
                        : 'Ask a question'
                    }
                    onClick={() => {
                      setReplaceQuestion(true);
                      setQuestionText('');
                    }}
                  />
                </S.ReplaceButton>
              </>
            )}
          </S.ChatContainer>
        ) : null}
      </S.LivekitSidebarInner>
      <S.LivekitSidebarNavigation>
        {roomState.modControls && (
          <S.LivekitSidebarNavItem
            $active={currentTab === 'Session info'}
            onClick={() =>
              setCurrentTab(currentTab === 'Session info' ? '' : 'Session info')
            }
          >
            <TooltipWrapper
              tooltipContent="Session info"
              position="right"
              tooltipOffset={24}
              size="small"
              referenceContent={
                <>
                  {currentTab === 'Session info' ? (
                    <InfoFilled size={24} />
                  ) : (
                    <Info size={24} />
                  )}
                </>
              }
            />
          </S.LivekitSidebarNavItem>
        )}
        <S.LivekitSidebarNavItem
          $active={currentTab === 'Attendees'}
          onClick={() =>
            setCurrentTab(currentTab === 'Attendees' ? '' : 'Attendees')
          }
        >
          <TooltipWrapper
            tooltipContent="Attendees"
            position="right"
            tooltipOffset={24}
            size="small"
            referenceContent={
              <S.ParticipantNavWrapper>
                {currentTab === 'Attendees' ? (
                  <ParticipantsFilled size={20} />
                ) : (
                  <Participants size={20} />
                )}
                <S.ParticipantCount>
                  {roomState?.participantsPresent?.length}
                </S.ParticipantCount>
              </S.ParticipantNavWrapper>
            }
          />
        </S.LivekitSidebarNavItem>
        {roomState.modControls && (
          <S.LivekitSidebarNavItem
            $active={currentTab === 'Layout'}
            onClick={handleLayoutClick}
          >
            <TooltipWrapper
              tooltipContent={
                showOnboardingLayoutTooltip
                  ? 'Add spice to your video recordings by dynamically changing the stage layout.'
                  : 'Layout'
              }
              position="right"
              tooltipOffset={24}
              callout={showOnboardingLayoutTooltip}
              width={showOnboardingLayoutTooltip ? '282px' : undefined}
              dismiss={
                showOnboardingLayoutTooltip
                  ? () => continueOnboarding('record-end')
                  : undefined
              }
              delayMount={showOnboardingLayoutTooltip}
              size={showOnboardingLayoutTooltip ? 'large' : 'small'}
              referenceContent={
                <>
                  {currentTab === 'Layout' ? (
                    <DesignServicesFilled size={24} />
                  ) : (
                    <DesignServices size={24} />
                  )}
                </>
              }
            />
          </S.LivekitSidebarNavItem>
        )}
        <S.LivekitSidebarNavItem
          $active={currentTab === 'Questions'}
          onClick={() =>
            setCurrentTab(currentTab === 'Questions' ? '' : 'Questions')
          }
        >
          <TooltipWrapper
            tooltipContent="Questions"
            position="right"
            tooltipOffset={24}
            size="small"
            referenceContent={
              <>
                {currentTab === 'Questions' ? (
                  <QuestionsFilled size={24} />
                ) : (
                  <S.IconWrapper>
                    <Questions size={24} />
                    {roomState.unreadQuestions > 0 &&
                      currentTab !== 'Questions' && (
                        <S.UnreadFlag>
                          {roomState.unreadQuestions > 99
                            ? '99+'
                            : roomState.unreadQuestions}
                        </S.UnreadFlag>
                      )}
                  </S.IconWrapper>
                )}
              </>
            }
          />

          {UnreadMessageCallOut}
        </S.LivekitSidebarNavItem>
        <S.LivekitSidebarNavItem
          $active={currentTab === 'Chat'}
          onClick={() => setCurrentTab(currentTab === 'Chat' ? '' : 'Chat')}
        >
          <TooltipWrapper
            tooltipContent="Chat"
            position="right"
            tooltipOffset={24}
            size="small"
            referenceContent={
              <>
                {currentTab === 'Chat' ? (
                  <ChatFilled size={24} />
                ) : (
                  <S.IconWrapper>
                    <Chat size={24} />
                    {roomState.unreadChatMessages > 0 &&
                      currentTab !== 'Chat' && (
                        <S.UnreadFlag>
                          {roomState.unreadChatMessages > 99
                            ? '99+'
                            : roomState.unreadChatMessages}
                        </S.UnreadFlag>
                      )}
                  </S.IconWrapper>
                )}
              </>
            }
          />
          {UnreadChatCallOut}
        </S.LivekitSidebarNavItem>
        {roomState.modControls && (
          <S.LivekitSidebarNavItem
            $active={currentTab === 'Recordings'}
            onClick={handleRecordingsClick}
          >
            <TooltipWrapper
              tooltipContent={
                onboardingStep === 'recordings'
                  ? 'Congratulations on recording your first video! Find your recordings here.'
                  : 'Recordings'
              }
              position="right"
              callout={onboardingStep === 'recordings'}
              tooltipOffset={24}
              width={onboardingStep === 'recordings' ? '282px' : undefined}
              dismiss={
                onboardingStep === 'recordings'
                  ? () => continueOnboarding(null)
                  : undefined
              }
              delayMount={onboardingStep === 'recordings'}
              size={onboardingStep === 'recordings' ? 'large' : 'small'}
              referenceContent={
                <>
                  {currentTab === 'Recordings' ? (
                    <FolderFilled size={24} />
                  ) : (
                    <S.IconWrapper>
                      <Folder size={24} />
                      {roomState.unseenRecordings > 0 &&
                        currentTab !== 'Recordings' && (
                          <S.UnreadFlag>
                            {roomState.unseenRecordings}
                          </S.UnreadFlag>
                        )}
                    </S.IconWrapper>
                  )}
                </>
              }
            />
          </S.LivekitSidebarNavItem>
        )}
        <S.SettingsButton>
          <TooltipWrapper
            tooltipContent="Settings"
            position="right"
            size="small"
            referenceContent={
              <IconButton
                size="medium"
                type="tertiary"
                icon="Settings"
                onClick={() => dispatch(toggleSettingsModal(true))}
              />
            }
          />
        </S.SettingsButton>
      </S.LivekitSidebarNavigation>
    </S.LivekitSidebar>
  );
};

export const DesktopSidebar = withTheme(DesktopSidebarComponent);
