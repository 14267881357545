import {VideoServiceOptions} from 'bright-livekit';
import {
  IVideoService,
  IVideoServiceConnectParams,
} from 'bright-livekit/services/video/IVideoService';
import {TrackType} from 'bright-livekit/types/BrightParticipant';
import {CaptionEntry} from 'bright-livekit/types/CaptionEntry';
import {IParticipant} from 'bright-livekit/types/participant/IParticipant';
import {TwilioParticipant} from 'bright-livekit/types/participant/TwilioParticipant';
import {ITrack} from 'bright-livekit/types/track/ITrack';
import {ITrackPublication} from 'bright-livekit/types/track/ITrackPublication';
import {TwilioTrackPublication} from 'bright-livekit/types/track/TwilioTrackPublication';
import {
  connect,
  Participant,
  RemoteParticipant,
  LocalParticipant,
  Room,
  LocalVideoTrack,
  RemoteTrackPublication,
} from 'twilio-video';
import {EventRegister} from '../EventRegister';
export class TwilioVideoService implements IVideoService {
  room?: Room;
  private _participants: WeakMap<Participant, TwilioParticipant>;
  private _twilioParticipant?: TwilioParticipant;
  private _screenShareTrack?: LocalVideoTrack;
  localParticipant?: IParticipant;
  onParticipantLeaveHandler?: (participant: IParticipant) => void;
  onParticipantTrackPublishedHandler?: (
    publication: ITrackPublication,
    participant: IParticipant
  ) => void;
  onParticipantsChangedHandler?: () => void;
  onDisconnectHandler?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(options: VideoServiceOptions) {
    this._participants = new WeakMap();
  }
  async connect(params: IVideoServiceConnectParams) {
    this.room = await connect(params.token, {
      name: params.sessionId,
      automaticSubscription: true,
      audio: false,
      video: false,
      bandwidthProfile: {
        video: {
          clientTrackSwitchOffControl: 'manual',
        },
      },
    });
    this._twilioParticipant = new TwilioParticipant(this.room.localParticipant);
    this.localParticipant = this._twilioParticipant;
    this.room.once('disconnected', () => this.disconnect());
  }
  async disconnect() {
    this.removeHandlers();
    if (this.onDisconnectHandler) {
      this.onDisconnectHandler();
    }
  }
  private _getParticipant(participant: LocalParticipant | RemoteParticipant) {
    let part = this._participants.get(participant);
    if (!part) {
      part = new TwilioParticipant(participant);
      this._participants.set(participant, part);
    }
    return part;
  }

  public get participants(): IParticipant[] {
    if (!this.room) {
      return [];
    }
    const remoteParticipants = Array.from(this.room.participants.values()).map(
      p => this._getParticipant(p)
    );
    if (this.localParticipant) {
      return [this.localParticipant, ...remoteParticipants];
    } else {
      return remoteParticipants;
    }
  }

  switchActiveDevice(
    kind: 'audioinput' | 'videoinput' | 'audiooutput',
    deviceId: string
  ) {
    this._twilioParticipant?.switchActiveDevice(kind, deviceId);
  }
  async toggleScreenshare(
    enabled: boolean,
    shareAudio = true
  ): Promise<ITrack[] | undefined> {
    if (!this.room) {
      return;
    }
    if (enabled) {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: {frameRate: 15},
        audio: shareAudio,
      });
      this._screenShareTrack = new LocalVideoTrack(stream.getTracks()[0], {
        logLevel: 'debug',
        name: 'screenshare',
      });
      this.room.localParticipant.publishTrack(this._screenShareTrack);
      this._screenShareTrack.once('stopped', () =>
        this.toggleScreenshare(false)
      );
      const track = this.localParticipant?.getTrack(
        TrackType.ScreenShare
      )?.track;
      return track ? [track] : undefined;
    } else {
      if (this._screenShareTrack) {
        this._screenShareTrack.stop();
        this.room.localParticipant.unpublishTrack(this._screenShareTrack);
      }
      const track = this.localParticipant?.getTrack(TrackType.Video)?.track;
      if (this.onParticipantsChangedHandler) {
        this.onParticipantsChangedHandler();
      }
      return track ? [track] : undefined;
    }
  }

  private _participantTrackPublishedConverter = (
    handler: (
      publication: TwilioTrackPublication,
      participant: TwilioParticipant
    ) => void
  ) => {
    return (
      publication: RemoteTrackPublication,
      participant: RemoteParticipant
    ) => {
      const part = this._getParticipant(participant);
      const pub = part.getTrackPublication(publication);
      handler(pub, part);
    };
  };
  onParticipantTrackPublished(
    handler: (publication: ITrackPublication, participant: IParticipant) => void
  ) {
    this.onParticipantTrackPublishedHandler = handler;
    this.room?.on(
      'trackPublished',
      EventRegister.wrap(
        this._participantTrackPublishedConverter,
        this.onParticipantTrackPublishedHandler
      )
    );
  }

  private _participantConverter = (
    handler: (participant: TwilioParticipant) => void
  ) => {
    return (participant: RemoteParticipant) => {
      handler(this._getParticipant(participant));
    };
  };
  removeHandlers() {
    if (this.onParticipantsChangedHandler && this.room) {
      this.room
        .off('participantConnected', this.onParticipantsChangedHandler)
        .off('participantDisconnected', this.onParticipantsChangedHandler)
        .off('trackPublished', this.onParticipantsChangedHandler)
        .off('trackUnpublished', this.onParticipantsChangedHandler)
        .off('trackSubscribed', this.onParticipantsChangedHandler)
        .off('trackUnsubscribed', this.onParticipantsChangedHandler)
        .off('trackSwitchedOn', this.onParticipantsChangedHandler)
        .off('trackSwitchedOff', this.onParticipantsChangedHandler)
        .off('trackEnabled', this.onParticipantsChangedHandler)
        .off('trackDisabled', this.onParticipantsChangedHandler);
    }
    if (this.onParticipantLeaveHandler) {
      this.room?.off(
        'participantDisconnected',
        EventRegister.wrap(
          this._participantConverter,
          this.onParticipantLeaveHandler
        )
      );
    }
  }
  onParticipantChanged(handler: () => void) {
    if (!this.room) {
      return;
    }
    this.onParticipantsChangedHandler = handler;
    this.room
      .on('participantConnected', this.onParticipantsChangedHandler)
      .on('participantDisconnected', this.onParticipantsChangedHandler)
      .on('trackPublished', this.onParticipantsChangedHandler)
      .on('trackUnpublished', this.onParticipantsChangedHandler)
      .on('trackSubscribed', this.onParticipantsChangedHandler)
      .on('trackUnsubscribed', this.onParticipantsChangedHandler)
      .on('trackSwitchedOn', this.onParticipantsChangedHandler)
      .on('trackSwitchedOff', this.onParticipantsChangedHandler)
      .on('trackEnabled', this.onParticipantsChangedHandler)
      .on('trackDisabled', this.onParticipantsChangedHandler);
  }
  onParticipantLeave(handler: (participant: IParticipant) => void) {
    this.onParticipantLeaveHandler = handler;
    this.room?.on(
      'participantDisconnected',
      EventRegister.wrap(
        this._participantConverter,
        this.onParticipantLeaveHandler
      )
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClosedCaptionReceived(handler: (captions: CaptionEntry[]) => void) {
    /* TODO */
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onActiveSpeakerChanged(handler: (speakers: IParticipant[]) => void) {
    /* TODO */
  }
  onDisconnect(handler: () => void) {
    this.onDisconnectHandler = handler;
  }
}
