import {BranchEvent} from '@brightlive/shared/helpers/enums';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    branch: any;
  }
}
/**
 * Track branch event
 *
 * @param   {BranchEvent}  event  Event type
 * @param   {any}  customData  Custom data for branch event
 * @param   {any}  contentItems  Content Items data for branch event
 * @param   {string}  eventAlias  Bright alias for event
 *
 */
export const branchTrackEvent = (
  event: BranchEvent,
  customData: unknown,
  contentItems: unknown,
  eventAlias: string
) => {
  if (window) {
    try {
      window.branch.logEvent(event, customData, contentItems, eventAlias);
    } catch (err) {
      //Don't stop execution for tracking errors
      console.error(err);
    }
  }
};

/**
 * Init branch identity
 *
 * @param   {string}  userID  User's ID
 *
 */
export const branchInitIdentity = (userID: string) => {
  if (window) {
    try {
      window.branch.setIdentity(userID);
    } catch (err) {
      //Don't stop execution for tracking errors
      console.error(err);
    }
  }
};

/**
 * Reset branch identity on logout
 *
 */
export const branchLogout = () => {
  if (window) {
    try {
      window.branch.logout();
    } catch (err) {
      //Don't stop execution for tracking errors
      console.error(err);
    }
  }
};
