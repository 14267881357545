import {Backends, IVideoService} from 'bright-livekit';
import {LivekitVideoUtils} from './utils/LivekitVideoUtils';
import {VideoUtils} from './utils/VideoUtils';
import {TwilioVideoUtils} from './utils/TwilioVideoUtils';
import {LivekitVideoService} from './video/LivekitVideoService';
import {TwilioVideoService} from './video/TwilioVideoService';

export interface VideoServiceOptions {
  resolution: '720p' | '1080p';
}

export class VideoServiceFactory {
  static getVideoService(
    backend: Backends,
    options: VideoServiceOptions
  ): IVideoService {
    switch (backend) {
      case Backends.Livekit:
        return new LivekitVideoService(options);
      case Backends.Twilio:
        return new TwilioVideoService(options);
    }
  }
  static getVideoUtils(backend: Backends): VideoUtils {
    switch (backend) {
      case Backends.Livekit:
        return new LivekitVideoUtils();
      case Backends.Twilio:
        return new TwilioVideoUtils();
    }
  }
}
