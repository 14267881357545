import React from 'react';
// Components
import {
  LayoutUpdateProps,
  LayoutTypes,
  ScreenShareLayoutTypes,
  NameDisplayTypes,
  BannerLayoutTypes,
  RoomState,
} from 'bright-livekit';
import LayoutSection from './LayoutSection';
import BrandingSection from './BrandingSection';
import Divider from '@brightlive/shared/components/Divider';
// Images
import Grid from 'public/images/layout-grid.svg';
import PictureInPicture from 'public/images/layout-picture-in-picture.svg';
import HostOnly from 'public/images/layout-host-only.svg';
import SpeakerOnly from 'public/images/layout-speaker-only.svg';
import SplitHostOnLeft from 'public/images/layout-split-host-on-left.svg';
import SplitHostOnRight from 'public/images/layout-split-host-on-right.svg';
import ScreenShareGrid from 'public/images/layout-screenshare-grid.svg';
import ScreenShareMain from 'public/images/layout-screenshare-screenshare-main.svg';
import SpeakerMain from 'public/images/layout-screenshare-speaker-main.svg';
import ScreenShareOnly from 'public/images/layout-screenshare-screenshare-only.svg';
import NameBlock from './NameDisplayImages/Block';
import NameAngled from './NameDisplayImages/Angled';
import NamePills from './NameDisplayImages/Pills';
import BannerBottom from 'public/images/layout-banner-bottom.svg';
import BannerTop from 'public/images/layout-banner-top.svg';
// Helpers
import {Hex} from '@brightlive/shared/helpers/interfaces';
// Styles
import S from './style';

export interface LayoutItemBase {
  label: string;
  image: string | React.ReactElement;
  value:
    | LayoutTypes
    | ScreenShareLayoutTypes
    | NameDisplayTypes
    | BannerLayoutTypes;
  enabled?: boolean;
}

export type SetLayoutFunction = <K extends keyof LayoutUpdateProps>({
  key,
  value,
}: {
  key: K;
  value: LayoutUpdateProps[K];
}) => void;

export interface LayoutBase {
  currentValue:
    | LayoutTypes
    | ScreenShareLayoutTypes
    | NameDisplayTypes
    | BannerLayoutTypes;
  setLayout: SetLayoutFunction;
}

interface LayoutViewProps {
  sessionID: string;
  setLayout: SetLayoutFunction;
  currentLayout: LayoutTypes;
  currentScreenShareLayout: ScreenShareLayoutTypes;
  currentBrandColor: Hex;
  currentNameDisplay: NameDisplayTypes;
  nameDisplayVisible: boolean;
  bannerVisible: boolean;
  currentBannerImage: string;
  currentBannerLayout: BannerLayoutTypes;
  roomState: RoomState;
}

export const LayoutView = ({
  sessionID,
  setLayout,
  currentLayout,
  currentScreenShareLayout,
  currentBrandColor,
  currentNameDisplay,
  nameDisplayVisible,
  bannerVisible,
  currentBannerImage,
  currentBannerLayout,
  roomState,
}: LayoutViewProps) => {
  const generalItems: LayoutItemBase[] = [
    {
      label: 'Split (host on left)',
      image: SplitHostOnLeft,
      value: LayoutTypes.SplitLeft,
    },
    {
      label: 'Split (host on right)',
      image: SplitHostOnRight,
      value: LayoutTypes.SplitRight,
      enabled: roomState.featureCheck('advancedLayouts'),
    },
    {
      label: 'Grid',
      image: Grid,
      value: LayoutTypes.Grid,
      enabled: roomState.featureCheck('advancedLayouts'),
    },
    {
      label: 'Picture-in-picture',
      image: PictureInPicture,
      value: LayoutTypes.PictureInPicture,
      enabled: roomState.featureCheck('advancedLayouts'),
    },
    {
      label: 'Speaker only',
      image: SpeakerOnly,
      value: LayoutTypes.Speaker,
    },
    {
      label: 'Host Only',
      image: HostOnly,
      value: LayoutTypes.CreatorOnly,
      enabled: roomState.featureCheck('advancedLayouts'),
    },
  ];

  const screenShareItems: LayoutItemBase[] = [
    {
      label: 'Grid',
      image: ScreenShareGrid,
      value: ScreenShareLayoutTypes.Grid,
    },
    {
      label: 'Screen share main',
      image: ScreenShareMain,
      value: ScreenShareLayoutTypes.ScreenShareMain,
    },
    {
      label: 'Speaker main',
      image: SpeakerMain,
      value: ScreenShareLayoutTypes.SpeakerMain,
    },
    {
      label: 'No videos',
      image: ScreenShareOnly,
      value: ScreenShareLayoutTypes.ScreenShareOnly,
    },
  ];

  const nameItems: LayoutItemBase[] = [
    {
      label: 'Block',
      image: <NameBlock brandColor={currentBrandColor} />,
      value: NameDisplayTypes.Block,
    },
    {
      label: 'Pills',
      image: <NamePills brandColor={currentBrandColor} />,
      value: NameDisplayTypes.Pills,
    },
    {
      label: 'Angled',
      image: <NameAngled brandColor={currentBrandColor} />,
      value: NameDisplayTypes.Angled,
    },
  ];

  const bannerItems: LayoutItemBase[] = [
    {
      label: 'Bottom banner',
      image: BannerBottom,
      value: BannerLayoutTypes.Bottom,
    },
    {
      label: 'Top Banner',
      image: BannerTop,
      value: BannerLayoutTypes.Top,
    },
  ];

  return (
    <S.LayoutView>
      <S.Content>
        <LayoutSection
          title="General"
          items={generalItems}
          setLayout={setLayout}
          currentValue={currentLayout}
          updateKey="stageLayout"
        />
        <Divider />
        <LayoutSection
          title="Screenshare"
          enabled={roomState.featureCheck('screenshare')}
          items={screenShareItems}
          setLayout={setLayout}
          currentValue={currentScreenShareLayout}
          updateKey="screenShareLayout"
        />
        <Divider />
        <BrandingSection
          sessionID={sessionID}
          title="Branding"
          nameItems={nameItems}
          currentBrandColor={currentBrandColor}
          currentNameDisplay={currentNameDisplay}
          nameDisplayVisible={nameDisplayVisible}
          bannerVisible={bannerVisible}
          currentBannerImage={currentBannerImage}
          currentBannerLayout={currentBannerLayout}
          bannerItems={bannerItems}
          setLayout={setLayout}
          brandingEnabled={roomState.featureCheck('branding')}
        />
      </S.Content>
    </S.LayoutView>
  );
};
