import {useCallback, useEffect, useState} from 'react';
import {
  collection,
  getFirestore,
  query,
  orderBy,
  startAt,
  Timestamp,
  updateDoc,
  doc,
  limitToLast,
} from 'firebase/firestore';
import {useCollection, useDocument} from 'react-firebase-hooks/firestore';
import {
  BrightParticipant,
  RoomState,
  RoomOptions,
  Role,
  LayoutTypes,
  ScreenShareLayoutTypes,
  CaptionEntry,
  IVideoService,
  BrightRoomEvent,
  NameDisplayTypes,
  BannerLayoutTypes,
} from 'bright-livekit';
import {
  TrackSubscriptionMode,
  TrackType,
} from 'bright-livekit/types/BrightParticipant';
import {Session, Hex} from '@brightlive/shared/helpers/interfaces';
import {
  VideoServiceFactory,
  VideoServiceOptions,
} from 'bright-livekit/services/VideoServiceFactory';
import {IParticipant} from 'bright-livekit/types/participant/IParticipant';
import {ITrackPublication} from 'bright-livekit/types/track/ITrackPublication';
import {VideoUtils} from 'bright-livekit/services/utils/VideoUtils';
import {useInterval} from '@brightlive/shared/hooks/useInterval';
import {hasCameraPermission} from '@brightlive/shared/helpers/cameraPermission';
import moment from 'moment';
import {TrackingService} from 'bright-livekit/services/TrackingService';
import {useStateRef} from '@brightlive/shared/hooks/useStateRef';
import {toggleAlertBanner} from 'redux/ui/actions';
import {useDispatch} from 'react-redux';
import {toggleMessageCallout} from 'redux/ui/actions';
import useDebounce from '@brightlive/shared/hooks/useDebounce';
let joinTime: Date | null = null;

const DEFAULT_BRAND_COLOR = '#252526';

export function useRoom(options: RoomOptions): RoomState {
  const dispatch = useDispatch();
  const [videoService, setVideoService] = useState<IVideoService>();
  const [videoUtils] = useState<VideoUtils>(
    VideoServiceFactory.getVideoUtils(options.backend)
  );
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [modControls, setModControls] = useState(false);
  const [isCreator, setIsCreator] = useState(false);
  const [onStage, setOnStage] = useState(false);
  const [stageFull, setStageFull] = useState(false);
  const [isStartable, setIsStartable] = useState(true);
  const [isStarting, setIsStarting] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [startedAt, setStartedAt] = useState<Date | null>(null);
  const [stageCleared, setStageCleared] = useState(false);
  const [cameraPermission, setCameraPermission] = useState(false);
  const [error, setError] = useState<Error>();
  const [screenShareTrack, setScreenShareTrack] = useState<ITrackPublication>();
  const [activeSpeakers, setActiveSpeakers] = useState<IParticipant[]>([]);
  const [, setScreenShareAudioTrack] = useState<ITrackPublication>();
  const [screenShareUser, setScreenShareUser] = useState('');
  const [lastSeenChatId, setLastSeenChatId] = useState<string | null>(null);
  const [lastSeenRecordingIndex, setLastSeenRecordingIndex] = useState<
    number | null
  >(null);
  const [lastSeenQuestions, setLastSeenQuestions] = useState<number[]>([]);
  const [lastSeenQuestionCallouts, setLastSeenQuestionCallouts] = useState<
    number[]
  >([]);
  const [selfParticipant, setSelfParticipant] = useState<BrightParticipant>();
  // We need a reference to all participants for user leave event handler which needs to update users' role when leaving a session
  const [participants, setParticipants, participantsRef] = useStateRef<
    BrightParticipant[]
  >([]);
  const [participantsPresent, setParticipantsPresent] = useState<
    BrightParticipant[]
  >([]);
  const [participantsOnStage, setParticipantsOnStage] = useState<
    BrightParticipant[]
  >([]);
  const [participantsSpeaking, setParticipantsSpeaking] = useState<
    BrightParticipant[]
  >([]);
  const [mainSpeaker, setMainSpeaker] = useState<BrightParticipant>();
  const debouncedMainSpeaker = useDebounce<BrightParticipant | undefined>(
    mainSpeaker,
    5000
  );
  const [participantsOffStage, setParticipantsOffStage] = useState<
    BrightParticipant[]
  >([]);
  const [participantsQandC, setParticipantsWithQandC] = useState<
    BrightParticipant[]
  >([]);
  const [videoParticipants, setVideoParticipants] = useState<IParticipant[]>(
    []
  );
  const [captions, setCC] = useState<CaptionEntry[]>([]);
  const [unreadQuestions, setUnreadQuestions] = useState<number>(0);
  const [unreadChatMessages, setUnreadChatMessages] = useState<number>(0);
  const [unseenRecordings, setUnseenRecordings] = useState<number>(0);
  const [sessionState, setSessionState] = useState<
    'loading' | 'permission-request' | 'pre-session' | 'in-session'
  >(options.isTestUser ? 'in-session' : 'permission-request');

  const [layout, setLayout] = useState<LayoutTypes>(LayoutTypes.SplitLeft);
  const [screenShareLayout, setScreenShareLayout] =
    useState<ScreenShareLayoutTypes>(ScreenShareLayoutTypes.Grid);
  const [brandColor, setBrandColor] = useState<Hex>(DEFAULT_BRAND_COLOR);
  const [nameDisplayVisible, setNameDisplayVisible] = useState(true);
  const [nameDisplay, setNameDisplay] = useState<NameDisplayTypes>(
    NameDisplayTypes.Block
  );
  const [bannerVisible, setBannerVisible] = useState(false);
  const [bannerImage, setBannerImage] = useState('');
  const [bannerLayout, setBannerLayout] = useState(BannerLayoutTypes.Bottom);
  const [participantsCollection] = useCollection(
    collection(getFirestore(), `sessions/${options.sessionID}/participants`)
  );

  if (joinTime === null) {
    joinTime = new Date();
  }
  useEffect(() => {
    let resolution: '720p' | '1080p' = '720p';
    if (
      options.session.creator?.id === options.currentUser.id &&
      featureCheck('resolution1080Host')
    ) {
      resolution = '1080p';
    } else if (featureCheck('resolution1080Guests')) {
      resolution = '1080p';
    }

    const serviceOptions: VideoServiceOptions = {
      resolution,
    };
    setVideoService(
      VideoServiceFactory.getVideoService(options.backend, serviceOptions)
    );
  }, []);

  const [notifications] = useCollection(
    query(
      collection(getFirestore(), `sessions/${options.sessionID}/notifications`),
      orderBy('timestamp', 'asc'),
      startAt(Timestamp.fromDate(joinTime))
    )
  );

  const [modChat] = useCollection(
    query(
      collection(getFirestore(), `sessions/${options.sessionID}/modChat`),
      orderBy('timestamp', 'asc'),
      startAt(Timestamp.fromDate(joinTime))
    )
  );

  const [chat] = useCollection(
    query(
      collection(getFirestore(), `sessions/${options.sessionID}/chat`),
      orderBy('timestamp', 'asc'),
      limitToLast(100)
    )
  );

  const [recordings] = useCollection(
    query(
      collection(getFirestore(), `sessions/${options.sessionID}/recordings`),
      orderBy('createdAt', 'desc')
    )
  );

  // Determine the number of unread messages
  useEffect(() => {
    if (chat && chat.docs.length > 0) {
      const currentChatLength = chat.docs.length - 1;
      const currentChatID = chat.docs[currentChatLength].id;
      // If loading if for the first time
      if (!lastSeenChatId) {
        return setLastSeenChatId(currentChatID);
      }
      const lastSeenChatIndex = chat.docs.findIndex(
        m => m.id === lastSeenChatId
      );
      // If currentChatLength is less than session storage
      // reset to 0
      if (currentChatLength < lastSeenChatIndex) {
        setLastSeenChatId(null);
        // If latestChatMessage timestamp is valid and different from the last seen
      } else if (currentChatLength >= lastSeenChatIndex) {
        //determine the number of unread messages
        setUnreadChatMessages(currentChatLength - lastSeenChatIndex);
        // Only send callout if new and not the current user
        if (currentChatLength > lastSeenChatIndex) {
          // dispatch callout for new chat message
          const chatData = chat.docs[currentChatLength].data();
          if (chatData && chatData.messageText && chatData.displayName) {
            dispatch(
              toggleMessageCallout(
                true,
                chatData.messageText,
                chatData.displayName,
                'chat'
              )
            );
          }
        } else if (currentChatLength === lastSeenChatIndex) {
          dispatch(toggleMessageCallout(false, '', '', ''));
        }
      }
    }
  }, [chat, lastSeenChatId]);
  // Determine the number of unread questions
  useEffect(() => {
    const seenQuestions = [...lastSeenQuestions];
    const seenQuestionCallouts = [...lastSeenQuestionCallouts];
    let unseenQuestions = 0;
    if (participantsQandC.length > 0) {
      for (const part of participantsQandC) {
        if (lastSeenQuestions.length <= 0 && part?.questionTimestamp) {
          seenQuestions.push(part.questionTimestamp);
        } else if (
          lastSeenQuestions.length > 0 &&
          part?.questionTimestamp &&
          !lastSeenQuestions.includes(part.questionTimestamp) &&
          selfParticipant?.id !== part.id
        ) {
          if (!lastSeenQuestionCallouts.includes(part.questionTimestamp)) {
            dispatch(
              toggleMessageCallout(
                true,
                part.question,
                part.displayName,
                'question'
              )
            );
            seenQuestionCallouts.push(part.questionTimestamp);
          }
          unseenQuestions++;
        }
      }
    }
    setLastSeenQuestions(seenQuestions);
    setLastSeenQuestionCallouts(seenQuestionCallouts);
    setUnreadQuestions(unseenQuestions);
  }, [participantsQandC]);

  // Determine the number of unread messages
  useEffect(() => {
    if (recordings) {
      const currentRecordingsLength = recordings.docs.length - 1;
      // If loading if for the first time
      if (!lastSeenRecordingIndex) {
        return setLastSeenRecordingIndex(currentRecordingsLength);
      }
      // If currentRecordingsLength is less than session storage
      // reset to 0
      if (currentRecordingsLength < lastSeenRecordingIndex) {
        setLastSeenRecordingIndex(0);
        // If latestChatMessage timestamp is valid and different from the last seen
      } else if (currentRecordingsLength >= lastSeenRecordingIndex) {
        //determine the number of unread messages
        setUnseenRecordings(currentRecordingsLength - lastSeenRecordingIndex);
      }
    }
  }, [recordings, lastSeenRecordingIndex]);

  const [sessionDocument] = useDocument(
    doc(getFirestore(), 'sessions', options.sessionID),
    {
      snapshotListenOptions: {includeMetadataChanges: true},
    }
  );
  hasCameraPermission().then(hasPermission =>
    setCameraPermission(hasPermission)
  );

  useInterval(() => {
    if (!sessionDocument?.data()) {
      return;
    }
    const sessionData = sessionDocument?.data() as Session;
    // Check for livestream keys
    const start = sessionData.startDate
      ? moment(sessionData.startDate)
      : moment();
    const now = moment().add(5, 'minutes');
    /** Session is startable if:
     *    It is an instant session
     *    The current user is an admin
     *    Or the start date is within 5 minutes
     */
    setIsStartable(
      sessionData.isInstant ||
        currentUser.roles.includes('admin') ||
        now > start
    );

    if (modControls) {
      const hasProblem = participants.some(
        p => !!p.errorMessage && [Role.Creator, Role.OnStage].includes(p.role)
      );
      if (hasProblem) {
        dispatch(
          toggleAlertBanner(
            'There is an issue recording your session',
            true,
            'alert'
          )
        );
      } else {
        dispatch(toggleAlertBanner('', false, 'warning'));
      }
    }
  }, 1000);

  const currentUser = options.currentUser;

  useEffect(() => {
    const speakerIds = activeSpeakers.map(s => s.identity);
    const participants = participantsOnStage.filter(p =>
      speakerIds.includes(p.id)
    );
    if (participants.length > 0) {
      setMainSpeaker(participants[0]);
    }
    setParticipantsSpeaking(participants);
  }, [activeSpeakers]);

  useEffect(() => {
    if (participantsCollection) {
      let hasRunningScreenshare = false;
      const updatedParticipants: BrightParticipant[] = [];
      let updatedOnStage: BrightParticipant[] = [];
      const updatedOffStage: BrightParticipant[] = [];
      const updatedPresent: BrightParticipant[] = [];
      let updatedQandC: BrightParticipant[] = [];
      for (const part of participantsCollection?.docs) {
        // This is a workaround, sometimes livekit adds two instances of the same participant to a room
        // If this happens we want to pick the one that has a connection quality if one exists
        const videoParticipant = videoParticipants
          .filter(p => p.identity === part.id)
          .sort(a => {
            if (a.isPresent) return -1;
            return 0;
          })
          .shift();

        let brightParticipant = participants.find(p => p.id === part.id);
        if (!brightParticipant) {
          brightParticipant = new BrightParticipant(part, videoParticipant);
        } else {
          brightParticipant.update(part, videoParticipant);
        }

        // Check if any showrunners or creators are publishing screenshare
        if (
          brightParticipant.role === Role.Creator ||
          brightParticipant.isShowRunner
        ) {
          const ssTrack = brightParticipant.videoParticipant?.getTrack(
            TrackType.ScreenShare
          );
          const ssAudioTrack = brightParticipant.videoParticipant?.getTrack(
            TrackType.ScreenShareAudio
          );

          if (ssTrack && ssTrack.isSubscribed && ssTrack.isEnabled) {
            hasRunningScreenshare = true;
            setScreenShareTrack(ssTrack);
            setScreenShareAudioTrack(ssAudioTrack);
            setScreenShareUser(brightParticipant.id);
          }
        }

        if (brightParticipant.id === currentUser.id) {
          setSelfParticipant(brightParticipant);
          if (
            brightParticipant.isShowRunner ||
            brightParticipant.role === Role.Creator
          ) {
            setModControls(true);
          }
          if (brightParticipant.role === Role.Creator) {
            setIsCreator(true);
          }
          const joiningStage =
            brightParticipant.role === Role.OnStage ||
            brightParticipant.role === Role.Creator;
          // Unmute user when they join the stage
          if (!onStage && joiningStage && videoParticipant) {
            videoService?.localParticipant?.setMicrophoneEnabled(true);
          }
          setOnStage(joiningStage);
          if (!joiningStage) {
            try {
              videoService?.localParticipant?.setMicrophoneEnabled(false);
            } catch (ex) {
              console.error(ex);
            }
          }
        }
        updatedParticipants.push(brightParticipant);
        let isVideoOn = false;
        if (brightParticipant.videoParticipant && brightParticipant.joined) {
          updatedPresent.push(brightParticipant);
          // If we are the creator and this is a user's first session they are joining fire a tracking event so it is registered from the creator's context
          if (isCreator && brightParticipant.isFirstSession) {
            TrackingService.fire({
              event: 'new_guest',
              currentUserId: brightParticipant.id,
              roles: currentUser.roles,
              uniqueIdentifier: brightParticipant.id,
              data: {
                session_id: sessionDocument?.id || '',
                host_id: currentUser.id,
              },
            });
          }
          if (
            brightParticipant.role === Role.Creator ||
            brightParticipant.role === Role.OnStage
          ) {
            updatedOnStage.push(brightParticipant);
            isVideoOn = true;
            brightParticipant.setTrackSubscriptions(TrackSubscriptionMode.Both);
          } else {
            updatedOffStage.push(brightParticipant);
          }
          if (
            brightParticipant.question &&
            !brightParticipant.questionAnswered
          ) {
            updatedQandC.push(brightParticipant);
            // If loading for first time, push all questions to seen array
            isVideoOn = true;
            // Currently SessionChatParticipantView controls the track subscriptions of their participant.
            // This is to allow for unsubscribing videos when comments scroll offscreen
            // brightParticipant.setTrackSubscriptions(
            //   TrackSubscriptionMode.VideoOnly
            // );
          }

          if (!isVideoOn) {
            brightParticipant.setTrackSubscriptions(TrackSubscriptionMode.None);
          }
        }
      }
      updatedOnStage = updatedOnStage.sort((a, b) => {
        if (a.role === Role.Creator) {
          return -1;
        } else if (b.role === Role.Creator) {
          return 1;
        }
        return (
          (a.roleChanged?.toMillis() || 0) - (b.roleChanged?.toMillis() || 0)
        );
      });
      // If any participants left while the creator was gone they will still be on stage but have no livekit record.
      // This can be removed once the user left webhook is implemented
      if (
        selfParticipant?.role === Role.Creator &&
        updatedOnStage.length > 0 &&
        !stageCleared
      ) {
        console.log('Clearing stage of absent participants');
        updatedParticipants
          .filter(p => p.role === Role.OnStage && !p.videoParticipant)
          .map(part => {
            if (part.firestore) {
              updateDoc(part.firestore.ref, {
                role: Role.GreenRoom,
              });
            }
          });
        setStageCleared(true);
      }
      updatedQandC = updatedQandC.sort((a, b) => {
        return (a.questionTimestamp || 0) - (b.questionTimestamp || 0);
      });
      setStageFull(updatedOnStage.length === videoUtils?.MAX_STAGE_USERS);
      setParticipants(updatedParticipants);
      setParticipantsPresent(updatedPresent);
      setParticipantsOnStage(updatedOnStage);
      setParticipantsOffStage(updatedOffStage);
      setParticipantsWithQandC(updatedQandC);
      // If the main speaker leaves replace them with the first person on stage
      if (!mainSpeaker?.videoParticipant && updatedOnStage.length > 0) {
        setMainSpeaker(updatedOnStage[0]);
      }
      if (!hasRunningScreenshare) {
        setScreenShareTrack(undefined);
        setScreenShareUser('');
      }
    }
  }, [participantsCollection, videoParticipants, stageCleared]);
  useEffect(() => {
    const sessionData = sessionDocument?.data();
    const recording = sessionData?.recording || false;
    const streaming = sessionData?.streaming || false;
    const startedAt = sessionData?.startedAt?.toDate?.() || null;
    setIsRecording(recording);
    setIsStarting(sessionData?.starting || false);
    setStartedAt(recording ? startedAt : null);
    const updatedLayout =
      sessionData?.layoutSettings?.stageLayout || LayoutTypes.SplitLeft;
    if (layout !== updatedLayout) {
      setLayout(updatedLayout);
    }
    const updatedScreenShareLayout =
      sessionData?.layoutSettings?.screenShareLayout || LayoutTypes.Grid;
    if (screenShareLayout !== updatedScreenShareLayout) {
      setScreenShareLayout(updatedScreenShareLayout);
    }

    const updatedBrandColor =
      sessionData?.layoutSettings?.brandColor || DEFAULT_BRAND_COLOR;
    if (brandColor !== updatedBrandColor) {
      setBrandColor(updatedBrandColor);
    }

    const updatedNameDisplayVisible =
      typeof sessionData?.layoutSettings?.nameDisplayVisible === 'undefined'
        ? true
        : sessionData.layoutSettings.nameDisplayVisible;
    if (nameDisplayVisible !== updatedNameDisplayVisible) {
      setNameDisplayVisible(updatedNameDisplayVisible);
    }

    const updatedNameDisplay =
      sessionData?.layoutSettings?.nameDisplay || NameDisplayTypes.Block;
    if (nameDisplay !== updatedNameDisplay) {
      setNameDisplay(updatedNameDisplay);
    }
    const updatedBannerVisible =
      typeof sessionData?.layoutSettings?.bannerVisible === 'undefined'
        ? true
        : sessionData.layoutSettings.bannerVisible;
    if (bannerVisible !== updatedBannerVisible) {
      setBannerVisible(updatedBannerVisible);
    }

    const updatedBannerImage = sessionData?.layoutSettings?.bannerImage || '';
    if (bannerImage !== updatedBannerImage) {
      setBannerImage(updatedBannerImage);
    }

    const updatedBannerLayout =
      sessionData?.layoutSettings?.bannerLayout || BannerLayoutTypes.Bottom;
    if (bannerLayout !== updatedBannerLayout) {
      setBannerLayout(updatedBannerLayout);
    }

    if (recording) {
      TrackingService.fire({
        event: 'start_recording',
        dependsOnEvent: 'spinup_recording',
        currentUser: options.currentUser,
        data: {
          session_id: options.sessionID,
        },
      });
    }
    if (streaming) {
      TrackingService.fire({
        event: 'start_livestream',
        dependsOnEvent: 'spinup_livestream',
        currentUser: options.currentUser,
        data: {
          session_id: options.sessionID,
        },
      });
    }
    // If the user is already in the session don't take them out
    if (sessionState === 'in-session') {
      return;
    }
    if (options.isRecorder) {
      setSessionState('in-session');
    } else if (cameraPermission) {
      setSessionState('pre-session');
    } else {
      setSessionState('permission-request');
    }
  }, [sessionDocument, cameraPermission, sessionState, isRecording]);

  const join = useCallback(
    async (displayName?: string) => {
      await videoUtils.join(
        options.session.id,
        options.currentUser.id,
        true,
        displayName
      );
      if (
        participantsRef.current.find(p => p.id === options.currentUser.id)
          ?.role === 'greenRoom'
      ) {
        await videoUtils.userJoinedToast(
          options.session.id,
          options.currentUser.id,
          displayName ?? ''
        );
      }
      setSessionState('in-session');
    },
    [selfParticipant]
  );
  const connectFn = useCallback(
    async (url: string, token: string) => {
      if (!videoService) {
        throw new Error('No Video Service');
      }
      setIsConnecting(true);
      await videoUtils.join(options.session.id, options.currentUser.id, false);

      try {
        await videoService.connect({
          sessionId: options.session.id,
          url,
          token,
        });
        if (options.isTestUser) {
          join();
        }

        if (window?.localStorage.getItem('livekit.videoInput')) {
          videoService.switchActiveDevice(
            'videoinput',
            window.localStorage.getItem('livekit.videoInput') as string
          );
        }
        if (window?.localStorage.getItem('livekit.audioInput')) {
          videoService.switchActiveDevice(
            'audioinput',
            window.localStorage.getItem('livekit.audioInput') as string
          );
        }

        const onParticipantsChanged = () => {
          setVideoParticipants(videoService.participants);
        };
        const onParticipantsLeave = (remoteParticipant: IParticipant) => {
          const leavingParticipant = participantsRef.current.find(
            p => p.id === remoteParticipant.identity
          );
          if (
            leavingParticipant?.role === Role.OnStage &&
            leavingParticipant.firestore
          ) {
            updateDoc(leavingParticipant.firestore.ref, {
              role: Role.GreenRoom,
            });
          }
          onParticipantsChanged();
        };
        const onParticipantsTrackPublished = (
          publication: ITrackPublication,
          participant: IParticipant
        ) => {
          const brightParticipant = participantsRef.current.find(
            p => p.id === participant.identity
          );
          brightParticipant?.ensureSubscription();
          onParticipantsChanged();
        };
        const handleClosedCaption = (captions: CaptionEntry[]) => {
          setCC(captions);
        };
        const handleActiveSpeakerChanged = (speakers: IParticipant[]) => {
          setActiveSpeakers(speakers);
        };
        videoService.onDisconnect(() => {
          setIsConnected(false);
          setTimeout(() => setVideoService(undefined));
          videoService.localParticipant?.off(
            BrightRoomEvent.LocalTrackPublished,
            onParticipantsChanged
          );
          videoService.localParticipant?.off(
            BrightRoomEvent.LocalTrackUnpublished,
            onParticipantsChanged
          );
        });
        // If the current user is the host they have the responsibility of removing users from the stage when they leave
        if (
          videoService.localParticipant?.identity ===
          options.session.creator?.id
        ) {
          videoService.onParticipantLeave(onParticipantsLeave);
        }
        videoService.onParticipantChanged(onParticipantsChanged);
        videoService.onParticipantTrackPublished(onParticipantsTrackPublished);
        videoService.onClosedCaptionReceived(handleClosedCaption);
        videoService.onActiveSpeakerChanged(handleActiveSpeakerChanged);
        videoService.localParticipant?.on(
          BrightRoomEvent.LocalTrackUnpublished,
          onParticipantsChanged
        );
        videoService.localParticipant?.on(
          BrightRoomEvent.LocalTrackPublished,
          onParticipantsChanged
        );
        onParticipantsChanged();
        setIsConnecting(false);
        setIsConnected(true);

        return videoService;
      } catch (error) {
        console.error(error);
        setIsConnecting(false);
        if (error instanceof Error) {
          setError(error);
        } else {
          setError(new Error('an error has occurred'));
        }

        return undefined;
      }
    },
    [videoService]
  );

  const featureCheck = (feature: string) => {
    return options.session.subscriptionDetails?.features[feature] ?? false;
  };

  const featureMessage = (feature: string) => {
    return (
      options.session.subscriptionDetails?.messages[feature] ??
      'This feature is not available at your current subscription level'
    );
  };

  const limitCheck = (limitName: string) => {
    return options.session.subscriptionDetails?.limits[limitName] ?? 0;
  };

  const planUpgradeAvailable = !['Pro', 'Unlimited'].includes(
    options.session.subscriptionDetails?.name ?? ''
  );

  return {
    join,
    connect: connectFn,
    isConnecting,
    isConnected,
    isStarting,
    isRecording,
    isRecorder: options.isRecorder,
    startedAt,
    videoService,
    videoUtils,
    isStartable,
    error,
    selfParticipant,
    participants,
    participantsPresent,
    participantsOnStage,
    participantsSpeaking,
    mainSpeaker: debouncedMainSpeaker?.videoParticipant
      ? debouncedMainSpeaker
      : mainSpeaker,
    participantsOffStage,
    participantsQandC,
    participantsCollection,
    videoParticipants,
    modControls,
    isCreator,
    onStage,
    stageFull,
    captions,
    notifications,
    modChat,
    chat,
    setLastSeenChatId,
    unreadChatMessages,
    setLastSeenRecordingIndex,
    unseenRecordings,
    setLastSeenQuestions,
    lastSeenQuestions,
    setUnreadQuestions,
    unreadQuestions,
    currentUser,
    session: options.session as Session,
    sessionDocument,
    sessionState,
    layout,
    screenShareLayout,
    brandColor,
    nameDisplayVisible,
    nameDisplay,
    bannerVisible,
    bannerImage,
    bannerLayout,
    screenShareTrack,
    screenShareUser,
    backend: options.backend,
    cameraPermission,
    setCameraPermission,
    featureCheck,
    featureMessage,
    limitCheck,
    planUpgradeAvailable,
  };
}
