import React from 'react';
import {useDispatch} from 'react-redux';
// Redux
import {toggleFeatureLockModal} from 'redux/ui/actions';
import Modal from '@brightlive/shared/components/Modal';
// Styles
import S from './style';

const FeatureLockModal = () => {
  const dispatch = useDispatch();

  return (
    <Modal
      closeModal={() => {
        dispatch(toggleFeatureLockModal(false));
      }}
      desktopWidth="500px"
      buttons={{
        button1Text: 'Cancel',
        button2Text: 'Upgrade now',
        button2OnClick: () => {
          window.open(
            `${process.env.NEXT_PUBLIC_BOOKING_URL}/pricing`,
            '_blank'
          );
          dispatch(toggleFeatureLockModal(false));
        },
      }}
    >
      <S.ModalWrapper>
        <S.Icon>✨</S.Icon>
        <S.ModalHeader>Upgrade your plan</S.ModalHeader>
        <S.ModalBody>
          This feature isn’t included in your current plan. Upgrade now to take
          full advantage of Bright.
        </S.ModalBody>
      </S.ModalWrapper>
    </Modal>
  );
};

export default React.memo(FeatureLockModal);
