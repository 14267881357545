import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';
import {media} from '@brightlive/shared/styles/breakpoints-v3';

const S = {
  PreSession: styled.div`
    padding-top: ${props => props.theme.sizing.mobile.navHeight};
    padding-bottom: 80px;
    background-color: ${props => props.theme.backgroundColor.body};

    ${media.LG} {
      padding-top: ${props => props.theme.sizing.desktop.navHeight};
      min-height: 100vh;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
    }
  `,

  PreSessionForm: styled.div`
    margin: ${props => props.theme.spacing['2XL']} 0;

    ${media.LG} {
      margin-top: 0;
    }
  `,

  InputWrapper: styled.div`
    margin-bottom: ${props => props.theme.spacing.LG};
  `,
  CheckboxWrapper: styled.div`
    margin-bottom: ${props => props.theme.spacing.LG};
  `,
  TermsLink: styled(Ag.Link)``,
  PreSessionUserCount: styled(Ag.ParagraphMD)`
    color: ${props => props.theme.contentColor.subdued};
    margin-top: ${props => props.theme.spacing['2XL']};
    margin-bottom: ${props => props.theme.spacing['2XL']};
    width: 100%;
    text-align: center;
    ${media.LG} {
      flex-direction: row;
      margin-bottom: ${props => props.theme.spacing['XL']};
    }
  `,
  PreSessionControls: styled.div`
    margin-top: ${props => props.theme.spacing.LG};
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.LG};
    width: 100%;
    ${media.LG} {
      flex-direction: row;
    }
  `,

  PreSessionVideoWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position relative;
    height: 192px;
    ${media.MD} {
      height: 428px;
    }
`,

  PreSessionVidStatWrapper: styled.div`
  position relative;
  width: 343px;
  height: 192px;
  ${media.MD} {
    width: 760px;
    height: 428px;
  }
`,

  PreSessionAvatarContainer: styled.div`
    background-color: ${props => props.theme.backgroundColor.disabled};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 343px;
    height: 192px;

    ${media.MD} {
      width: 760px;
      height: 428px;
    }
  `,

  PreSessionMessageWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  PreSessionMessage: styled(Ag.HeadingMD)`
    margin-left: ${props => props.theme.spacing.XS};
  `,

  PreSessionTitleDesktop: styled(Ag.HeadingLG)`
    display: none;
    ${media.LG} {
      display: block;
      padding-bottom: ${props => props.theme.spacing['2XL']};
    }
  `,

  PreSessionTitleMobile: styled(Ag.HeadingMD)`
    padding-bottom: ${props => props.theme.spacing['2XL']};
    ${media.LG} {
      display: none;
    }
  `,
  PreSessionJoinButton: styled.div`
    display: flex;
    position: fixed;
    z-index: 300;
    align-items: center;
    justify-content: center;
    bottom: 0;
    right: 0;
    left: 0;
    padding: ${props => props.theme.spacing.MD};
    background-color: ${props => props.theme.backgroundColor.body};
    border-top: ${props => props.theme.borderWidth[1]} solid
      ${props => props.theme.borderColor.subdued};
    ${media.LG} {
      z-index: 3000;
      border: none;
      position: relative;
      padding: 0;
    }
  `,

  PreSessionTitle: styled(Ag.HeadingMD)`
    padding-bottom: ${props => props.theme.spacing.XS};
  `,

  PreSessionText: styled(Ag.ParagraphMD)`
    padding-bottom: ${props => props.theme.spacing.MD};
    flex: 1;
  `,
  ShareWrapper: styled.div`
    padding-bottom: ${props => props.theme.spacing.MD};
    ${media.LG} {
      display: flex;
      gap: ${props => props.theme.spacing.MD};
      padding-bottom: ${props => props.theme.spacing.LG};
    }
  `,
  ProfileLink: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.theme.spacing.SM};
    background: ${props => props.theme.backgroundColor.disabled};
    border-radius: ${props => props.theme.borderRadius.MD};
    border: ${props => props.theme.borderWidth[1]} solid
      ${props => props.theme.borderColor.subdued};
    min-width: 0;
  `,

  PreSessionCopyLink: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  ProfileLinkText: styled(Ag.ParagraphMD)`
    padding-right: ${props => props.theme.spacing.MD};
    flex: 1;
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
  `,

  PreSessionNavLink: styled.div`
    ${media.LG} {
      margin-bottom: ${props => props.theme.spacing['2XL']};
    }
  `,

  LinkIcon: styled.img`
    width: 16px;
    margin-right: ${props => props.theme.spacing.XS};
  `,

  PreSessionCreator: styled(Ag.HeadingXS)`
    margin-left: ${props => props.theme.spacing.SM};
    margin-right: ${props => props.theme.spacing.SM};
    display: flex;
    align-items: center;
    gap: ${props => props.theme.spacing['2XS']};
  `,

  Video: styled.div`
    position: relative;
    text-align: center;
    border-radius: ${props => props.theme.borderRadius['2XL']};
    overflow: hidden;
  `,

  AvatarContainer: styled.div`
    background: ${props => props.theme.backgroundColor.disabled};
    border: ${props => props.theme.borderWidth[1]} solid
      ${props => props.theme.borderColor.subdued};
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
  `,

  Avatar: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,

  PreSessionStats: styled.div`
    display: flex;
    position: absolute;
    z-index: 1;
    gap: ${props => props.theme.spacing.XS};
    bottom: ${props => props.theme.spacing.SM};
    left: ${props => props.theme.spacing.MD};
  `,

  PreSessionErrorMessage: styled.div`
    margin-bottom: ${props => props.theme.spacing.LG};
  `,
  PreSessionVideoButton: styled.div``,
  Link: styled(Ag.Link)``,

  PreSessionQualityMessage: styled.div`
    margin-top: ${props => props.theme.spacing.LG};
  `,
  PreSessionDeviceToggle: styled.div`
    position: absolute;
    bottom: ${props => props.theme.spacing.MD};
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  `,
  PreSessionAudioButton: styled.div`
    margin-right: ${props => props.theme.spacing.MD};
  `,
};
export default S;
