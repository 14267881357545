import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
//Components
import {RoomState, GeneralSettings, DeviceSettings} from 'bright-livekit';
import Tab from '@brightlive/shared/components/Tab';
import Modal from '@brightlive/shared/components/Modal';
// Icons
import OpenInNew from '@brightlive/shared/icons/OpenInNew';
// Actions
import {toggleSettingsModal} from 'redux/ui/actions';
import {getLivestreamKeys} from 'redux/session/actions';
// Styles
import S from './style';

interface SettingsModalProps {
  sessionID: string;
  roomState: RoomState;
  setAlwaysShowControls?: Function;
  setShowQuestionPreview?: Function;
}

export const SettingsModal = ({
  sessionID,
  roomState,
  setAlwaysShowControls,
  setShowQuestionPreview,
}: SettingsModalProps) => {
  const [activeTab, setActiveTab] = useState<
    'audio' | 'general' | 'livestream'
  >('audio');
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(toggleSettingsModal(false));
  };

  useEffect(() => {
    if (roomState.currentUser.id === roomState.session.creator?.id) {
      dispatch(getLivestreamKeys(sessionID));
    }
  }, []);

  return (
    <Modal
      closeModal={onClose}
      mobileHeight="100%"
      desktopWidth="962px"
      bottomContent={
        <S.ModalBottom>
          <S.ModalBottomHeader>
            Changes are automatically saved
          </S.ModalBottomHeader>
          <S.ModalBottomText>
            Having trouble?&nbsp;
            <S.ModalBottomLink
              target="_blank"
              href="https://support.brightlive.com/hc/en-us"
            >
              Go to Help Center&nbsp;
              <OpenInNew size={16} contentColor="accent" />
            </S.ModalBottomLink>
          </S.ModalBottomText>
        </S.ModalBottom>
      }
    >
      <S.ModalContent>
        <S.ModalTabs>
          <Tab
            text="Audio & Video"
            type="default"
            selected={activeTab === 'audio'}
            onClick={() => setActiveTab('audio')}
          />
          <Tab
            text="General"
            type="default"
            selected={activeTab === 'general'}
            onClick={() => setActiveTab('general')}
          />
        </S.ModalTabs>
        <S.ModalBody>
          {activeTab === 'audio' && <DeviceSettings roomState={roomState} />}
          {activeTab === 'general' && (
            <GeneralSettings
              roomState={roomState}
              sessionID={sessionID}
              setAlwaysShowControls={setAlwaysShowControls}
              setShowQuestionPreview={setShowQuestionPreview}
            />
          )}
        </S.ModalBody>
      </S.ModalContent>
    </Modal>
  );
};
