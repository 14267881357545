import React from 'react';
//Components
import Button from '@brightlive/shared/components/Button';
// Styles
import S from './style';

export const UpgradeCard = ({
  text = 'This feature isn’t included in the free plan. Upgrade now to take full advantage of Bright.',
}: {
  text?: string;
}) => {
  return (
    <S.CardContent>
      <S.CardTitle>Upgrade your plan</S.CardTitle>
      <S.CardBody>{text}</S.CardBody>
      <Button
        type="primary inverse"
        size="medium"
        text="Upgrade"
        onClick={() =>
          window.open(
            `${process.env.NEXT_PUBLIC_BOOKING_URL}/pricing`,
            '_blank'
          )
        }
      />
    </S.CardContent>
  );
};
