import React from 'react';
import {RoomState} from 'bright-livekit/types/RoomState';
import {useSelector} from 'react-redux';
//Components
import {UpgradeCard} from 'bright-livekit/components/UpgradeCard';
import ContextualAlert from '@brightlive/shared/components/ContextualAlert';
import Divider from '@brightlive/shared/components/Divider';
import LivestreamToggle, {
  LivestreamServices,
} from 'components/session/LivestreamToggle';
// Redux
import {SessionReducer} from 'redux/session/reducer';
// Styles
import S from './style';

interface LiveStreamProps {
  sessionID: string;
  roomState: RoomState;
}

export const LivestreamSettings = ({sessionID, roomState}: LiveStreamProps) => {
  const livestreams = useSelector(
    (state: SessionReducer) => state.session.livestreams.simulcasts || []
  );
  const streamingEnabled = roomState.featureCheck('livestreamSingle');
  const multiStreamingEnabled = roomState.featureCheck('livestreamMulti');

  const selectedServices = livestreams.filter(service => {
    return service.enabled;
  });

  const serviceSelectionEnabled =
    streamingEnabled && (multiStreamingEnabled || selectedServices.length < 1);
  // Determine whether the toggle is disabled for provided service
  const isDisabled = service => {
    const existingStream = livestreams.find(c => c.service === service);
    return !serviceSelectionEnabled && !existingStream?.enabled;
  };
  return (
    <S.LivestreamSettings>
      <S.LivestreamParagraph>
        {roomState.isRecording
          ? 'These settings will take effect next time you start a recording'
          : 'Once you start recording, broadcast live to streaming platforms.'}
      </S.LivestreamParagraph>
      {!streamingEnabled && <UpgradeCard />}
      {streamingEnabled && (
        <>
          <LivestreamToggle
            service={LivestreamServices.youtube}
            title="Youtube"
            sessionID={sessionID}
            disabled={isDisabled(LivestreamServices.youtube)}
          />
          <Divider />
          <LivestreamToggle
            service={LivestreamServices.twitch}
            title="Twitch"
            sessionID={sessionID}
            disabled={isDisabled(LivestreamServices.twitch)}
          />
          <Divider />
          <LivestreamToggle
            service={LivestreamServices.facebook}
            title="Facebook"
            sessionID={sessionID}
            disabled={isDisabled(LivestreamServices.facebook)}
          />
          <Divider />
          <LivestreamToggle
            service={LivestreamServices.other}
            title="Other"
            sessionID={sessionID}
            disabled={isDisabled(LivestreamServices.other)}
          />
        </>
      )}
      {streamingEnabled &&
        !multiStreamingEnabled &&
        !serviceSelectionEnabled && (
          <ContextualAlert
            type="warning"
            text={`You’ve reached your livestream limit. ${
              roomState.planUpgradeAvailable
                ? 'Upgrade your plan to broadcast to more streaming platforms.'
                : ''
            }`}
          />
        )}
    </S.LivestreamSettings>
  );
};
