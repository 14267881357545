import {media} from '@brightlive/shared/styles/breakpoints-v3';
import Ag from '@brightlive/shared/styles/typography-v3';
import styled, {DefaultTheme} from 'styled-components';

const S = {
  UpgradeBanner: styled.div(
    (props: {$navExpanded: boolean; theme: DefaultTheme}) => `
      display: flex;
      background-color: ${props.theme.backgroundColor.inversePrimary};
      padding: ${props.theme.spacing.MD};
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      z-index: 3005;
      ${media.MD} {
        padding: ${props.theme.spacing.MD} 0;
        justify-content: center;
        align-items: center;
        width: ${
          props.$navExpanded
            ? 'calc((100vw / 3) * 2 + 15px)'
            : `calc(100% - ${props.theme.spacing['4XL']})`
        };
      }
      `
  ),

  UpgradeBannerText: styled(Ag.HeadingMD)`
    color: ${props => props.theme.contentColor.inverse};
    text-align: center;
    margin-right: ${props => props.theme.spacing.MD};
  `,
  Button: styled.div`
    display: none;
    ${media.MD} {
      display: block;
    }
  `,
};

export default S;
