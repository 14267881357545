import styled from 'styled-components';
import Ag from '@brightlive/shared/styles/typography-v3';

const S = {
  ModalWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Icon: styled(Ag.HeadingMD)`
    margin-bottom: ${props => props.theme.spacing.MD};
    font-size: ${props => props.theme.fontSize[1000]};
    line-height: ${props => props.theme.fontSize[1000]};
  `,
  ModalHeader: styled(Ag.HeadingMD)`
    margin-bottom: ${props => props.theme.spacing.XL};
  `,
  ModalBody: styled(Ag.ParagraphMD)``,
};
export default S;
